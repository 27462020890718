import Head from 'next/head'
import { CmsPageHreflangsFragment } from './CmsPageHreflangs.gql'
import { baseUrl } from './utils'

const formatHref = (locale: string | false, url: string) =>
  `${baseUrl}${locale ? `/${locale}` : ''}/${url}`

export const CmsPageHreflangs = (props: { hreflangs: CmsPageHreflangsFragment }) => {
  const { hreflangs } = props

  // We always want to show both the /en and the default hrefLang
  // If the current HG locale === 'en' we need to render both the <link ... hrefLang='en'/> and the <link ... hrefLang='x-default'/> based on that information
  // If the current HG local is nog 'en' we want to use the 'en' localization as our base for the x-default variant, but use the current locale as-is.

  return (
    <Head>
      {hreflangs.locale === 'en' ? (
        <>
          <link rel='alternate' href={formatHref(false, hreflangs.url)} hrefLang='x-default' />
          <link rel='alternate' href={formatHref('en', hreflangs.url)} hrefLang='en' />
        </>
      ) : (
        <>
          <link
            rel='alternate'
            href={formatHref(
              false,
              hreflangs.localizations?.find((item) => item.locale === 'en')!.url ?? '',
            )}
            hrefLang='x-default'
          />
          <link
            rel='alternate'
            href={formatHref(hreflangs.locale, hreflangs.url)}
            hrefLang={hreflangs.locale}
            key={hreflangs.locale}
          />
        </>
      )}
      {hreflangs.localizations.map((localization) => (
        <link
          rel='alternate'
          href={formatHref(localization.locale, localization.url)}
          hrefLang={localization.locale}
          key={localization.locale}
        />
      ))}
    </Head>
  )
}
